// import React from 'react';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from '../helper/firebase';
// import { sendMail } from '../components';
// eslint-disable-next-line prefer-destructuring
// const sendMail = require('../components').sendMail;
import emailjs from 'emailjs-com';


// class ContactForm extends React.Component {
const ContactForm  = () => {
  const initialState = {
    name: '',
    email: '',
    message: ''
  }

  const [state, setState] = useState( { ...initialState } );

  // state = { ...initialState }

  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_USER_ID
      )

      // .then((result) => {
      //     console.log(result.text);
      // },
      // (error) => {
      //     console.log(error.text);
      // });

      .then(res => {
        console.log(`Message sent: ${res.text}`)  
        setState({ ...initialState });
        toast.success('Message Sent');
      })
      .catch(err => console.log(err));

  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const sendMail = firebase.functions().httpsCallable('sendMail');
  //   const contactUs = firebase.database().ref().child('contactUs');
  //   contactUs.push(state).then(() => {
  //     setState({ ...initialState });
  //     toast.success('Message Sent');
  //     // eslint-disable-next-line no-console
  //     // sendMail().then(res => console.log(res.data));
  //     sendMail()
  //       // eslint-disable-next-line no-console
  //       .then(res => console.log(`Message sent: ${res.message}`))
  //       // eslint-disable-next-line no-console
  //       .catch(error => console.log(error));
  //   });
  // };

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value
    });
  };

  // render() {
  const { name, email, message } = state;
  return (
    <div
      className="col-sm-12 col-md-6 wow fadeInUp"
      data-wow-duration="0.8s"
      data-wow-delay="0.2s"
    >
      <form
        id="contactForm"
        className="single-form quate-form wow fadeInUp"
        data-toggle="validator"
        ref={form}
        onSubmit={sendEmail}
        // onSubmit={handleSubmit}
      >
        <div id="msgSubmit" className="h3 text-center hidden" />
        <div className="row">
          <div className="col-sm-12">
            <input
              name="name"
              className="contact-name form-control"
              id="name"
              type="text"
              placeholder="Name"
              required
              value={name}
              onChange={handleChange}
            />
          </div>

          <div className="col-sm-12">
            <input
              name="email"
              className="contact-subject form-control"
              id="email"
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={handleChange}
            />
          </div>

          <div className="col-sm-12">
            <textarea
              className="contact-message"
              id="message"
              name="message"
              rows="6"
              placeholder="Message"
              required
              value={message}
              onChange={handleChange}
            />
          </div>

          <div className="btn-form col-sm-12">
            <button
              type="submit"
              className="btn btn-fill btn-block"
              id="form-submit"
            >
              Send Message
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
// }

export default ContactForm;
