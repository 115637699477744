import React from 'react';
import './Settings.css';

class Settings extends React.Component {
  render() {
    return (
      <div className="container settings">
        <h2 className="center">Settings</h2>

        <div className="row">
          <div className="col-md-6">
            {/*  */}
          </div>
          <div className="col-md-6">
            {/*  */}
          </div>

        </div>
      </div>
    );
  }
}

export default Settings;
