import React from 'react';

const SocialIcons = props => (
  <ul {...props}>
    <li>
      <a
        href="https://facebook.com/engineer.koitoror"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-facebook" />
      </a>
    </li>
    <li>
      <a
        href="https://twitter.com/koitoror/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-twitter" />
      </a>
    </li>
    <li>
      <a
        href="https://github.com/koitoror/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-github" />
      </a>
    </li>
    <li>
      <a
        // href="https://stackoverflow.com/users/story/12165525"
        href="https://stackoverflow.com/users/12165525/koitoror?tab=profile"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-stack-overflow" />
      </a>
    </li>
    <li>
      <a
        href="https://linkedin.com/in/daniel-kamar/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-linkedin" />
      </a>
    </li>
    <li>
      <a
        href="https://medium.com/@koitoror"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-medium" />
      </a>
    </li>
    <li>
      <a
        href="https://profile.codersrank.io/leaderboard/developer?country=Kenya&city=Nairobi&technology=Python"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa"> 
          {/* <object data="/assets/images/icon_cdr.svg" width="56" height="38"> </object> */}
          {/* <embed src="/assets/images/icon_cdr.svg" /> */}
          {/* <img src="/assets/images/codersrank-icon.svg" width="18" height="18" alt="CodersRank"></img> */}
          <img src="/assets/images/icon_cdr.svg" width="18" height="18" alt="CodersRank"></img>

        </i>

      </a>
    </li>
    <li>
      <a
        href="https://www.hackerrank.com/profile/kamarster"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa"> 
          {/* <object data="/assets/images/icon_cdr.svg" width="56" height="38"> </object> */}
          {/* <embed src="/assets/images/icon_cdr.svg" /> */}
          {/* <img src="/assets/images/codersrank-icon.svg" width="18" height="18" alt="CodersRank"></img> */}
          <img src="/assets/images/hackerrank.svg" width="18" height="18" alt="CodersRank"></img>

        </i>

      </a>
    </li>
  </ul>
);

export default SocialIcons;
