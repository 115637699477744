import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Route, Routes } from "react-router-dom";

import path from './path';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Settings from '../pages/Settings';
import PageNotFound from '../pages/PageNotFound';

const Routers = () => (
  <Routes>
    {/* <Switch> */}
      <Route path={path.root} exact element={<Dashboard />} />
      <Route path={path.login} exact element={<Login />} />
      <Route path={path.settings} exact element={<Settings />} />
      <Route element={<PageNotFound />} />
    {/* </Switch> */}
  </Routes>
);

export default Routers;
