import React from 'react';

class Portfolio extends React.Component {
  render() {
    return (
      <section className='mh-portfolio' id='mh-portfolio'>
        <div className='container'>
          <div className='row section-separator'>
            <div
              className='section-title col-sm-12 wow fadeInUp'
              data-wow-duration='0.8s'
              data-wow-delay='0.1s'
            >
              <h3>My Projects Portfolio</h3>
            </div>
            <div className='part col-sm-12'>
              <div className='portfolio-nav col-sm-12' id='filter-button'>
                <ul>
                  <li
                    data-filter='*'
                    className='current wow fadeInUp'
                    data-wow-duration='0.8s'
                    data-wow-delay='0.1s'
                  >
                    {' '}
                    <span>All Categories</span>
                  </li>
                  <li
                    data-filter='.user-interface'
                    className='wow fadeInUp'
                    data-wow-duration='0.8s'
                    data-wow-delay='0.2s'
                  >
                    <span>Web Design FullStack</span>
                  </li>
                  <li
                    data-filter='.branding'
                    className='wow fadeInUp'
                    data-wow-duration='0.8s'
                    data-wow-delay='0.3s'
                  >
                    <span>API Design</span>
                  </li>
                  <li
                    data-filter='.mockup'
                    className='wow fadeInUp'
                    data-wow-duration='0.8s'
                    data-wow-delay='0.4s'
                  >
                    <span>Mockups & DB Design</span>
                  </li>
                  <li
                    data-filter='.ui'
                    className='wow fadeInUp'
                    data-wow-duration='0.8s'
                    data-wow-delay='0.5s'
                  >
                    <span>Frontend Design</span>
                  </li>
                </ul>
              </div>
              <div
                className='mh-project-gallery col-sm-12 wow fadeInUp'
                id='project-gallery'
                data-wow-duration='0.8s'
                data-wow-delay='0.5s'
              >
                <div className='portfolioContainer row'>
                  <div className='grid-item col-md-4 col-sm-6 col-xs-12 user-interface'>
                    <figure>
                      <img src='assets/images/portfolio/g1.png' alt='img04' />
                      <figcaption className='fig-caption'>
                        <i className='fa fa-search' />
                        <h5 className='title'>Debby Hand Knits</h5>
                        <span className='sub-title'>
                          E-Commence Microservices + GKE
                        </span>
                        {/* modal link here */}
                        <a href='https://github.com/koitoror/Debby-Hand-Knits' />{' '}
                      </figcaption>
                    </figure>
                  </div>
                  <div className='grid-item col-md-4 col-sm-6 col-xs-12 user-interface'>
                    <figure>
                      {/* <img src='assets/images/portfolio/elector.png' alt='img04' /> */}
                      <img src='assets/images/portfolio/elector.png' alt='img04' />

                      <figcaption className='fig-caption'>
                        <i className='fa fa-search' />
                        <h5 className='title'>Elections Tallying Assistant</h5>
                        <span className='sub-title'>
                          Nuxt-Vue + Supabase 
                        </span>
                        {/* modal link here */}
                        <a href='https://degem.link' />{' '}
                      </figcaption>
                    </figure>
                  </div>
                  <div className='grid-item col-md-4 col-sm-6 col-xs-12 ui mockup'>
                    <figure>
                      <img src='assets/images/portfolio/g2.png' alt='img04' />
                      <figcaption className='fig-caption'>
                        <i className='fa fa-search' />
                        <h5 className='title'>Imani Event Planners</h5>
                        <span className='sub-title'>
                          MVT + AWS Route53-EC2 - now.sh - improvMX
                        </span>
                        {/* modal link here */}
                        <a href='https://template-lake.vercel.app' />
                        {/* <a href='https://imani.events' />{' '} */}
                      </figcaption>
                    </figure>
                  </div>
                  <div className='grid-item col-md-4 col-sm-6 col-xs-12 user-interface'>
                    <figure>
                      <img src='assets/images/portfolio/g3.png' alt='img04' />
                      <figcaption className='fig-caption'>
                        <i className='fa fa-search' />
                        <h5 className='title'>Koitoror University</h5>
                        <span className='sub-title'>Learning Manager</span>
                        {/* modal link here */}
                        <a href='https://github.com/koitoror/Koitoror-University' />{' '}
                      </figcaption>
                      {/* <a href="https://koitororuniversity.ml" />                      </figcaption> */}
                    </figure>
                  </div>
                  <div className='grid-item col-md-4 col-sm-6 col-xs-12 branding'>
                    <figure>
                      <img
                        src='assets/images/portfolio/send-it.webp'
                        alt='img04'
                      />
                      <figcaption className='fig-caption'>
                        <i className='fa fa-search' />
                        <h5 className='title'>Send - IT API</h5>
                        <span className='sub-title'>Heroku + REST</span>
                        {/* modal link here */}
                        <a href='https://github.com/koitoror/SendIT' />{' '}
                      </figcaption>
                      {/* <a href="https://send-it-ke.herokuapp.com" />                      </figcaption> */}
                    </figure>
                  </div>
                  <div className='grid-item col-md-4 col-sm-6 col-xs-12 user-interface'>
                    <figure>
                      <img src='assets/images/portfolio/g4.png' alt='img04' />
                      <figcaption className='fig-caption'>
                        <i className='fa fa-search' />
                        <h5 className='title'>SAYARE-RTV </h5>
                        <span className='sub-title'>
                          Elementor-Wordpress-PHP & Google API's
                        </span>
                        {/* modal link here */}
                        <a href='https://www.sayare.co.ke' />
                      </figcaption>
                    </figure>
                  </div>
                  <div className='grid-item col-md-4 col-sm-6 col-xs-12 branding'>
                    <figure>
                      <img src='assets/images/portfolio/g6.png' alt='img04' />
                      <figcaption className='fig-caption'>
                        <i className='fa fa-search' />
                        <h5 className='title'>Chicken-Farm-Ke</h5>
                        <span className='sub-title'>
                          E-commerce 3-Tier Architecture
                        </span>
                        {/* modal link here */}
                        <a href='https://github.com/BuildForSDG/Team-183-Backend' />
                        {/* <a href="https://chicken-farm-ke.herokuapp.com" />                       */}
                      </figcaption>
                    </figure>
                  </div>
                  <div className='grid-item col-md-4 col-sm-6 col-xs-12 branding'>
                    <figure>
                      <img src='assets/images/portfolio/g8.png' alt='img04' />
                      <figcaption className='fig-caption'>
                        <i className='fa fa-search' />
                        <h5 className='title'>
                          Converge Backend Microservices
                        </h5>
                        <span className='sub-title'>
                          Graph-QL & Push Notification{' '}
                        </span>
                        {/* modal link here */}
                        <a href='https://github.com/andela/mrm_api' />{' '}
                      </figcaption>
                    </figure>
                  </div>
                  <div className='grid-item col-md-4 col-sm-6 col-xs-12 ui'>
                    <figure>
                      <img src='assets/images/portfolio/g9.png' alt='img04' />
                      <figcaption className='fig-caption'>
                        <i className='fa fa-search' />
                        <h5 className='title'>Approved Study Book Club</h5>
                        <span className='sub-title'>MVT + NoSQL</span>
                        {/* modal link here */}
                        <a href='http://koitoror.pythonanywhere.com'>
                          Click Link Here
                        </a>
                      </figcaption>
                    </figure>
                  </div>
                  <div className='grid-item col-md-4 col-sm-6 col-xs-12 branding'>
                    <figure>
                      <img src='assets/images/portfolio/g7.png' alt='img04' />
                      <figcaption className='fig-caption'>
                        <i className='fa fa-search' />
                        <h5 className='title'>Covid-19 Estimator</h5>
                        <span className='sub-title'>Json/Xml & Logging</span>
                        {/* modal link here */}
                        <a href=' https://github.com/koitoror/c-19-ke' />
                        {/* <a href=" https://cov-19-ke.herokuapp.com" />                       */}
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mh-portfolio-modal' id='mh'>
          <div className='container'>
            <div className='row mh-portfolio-modal-inner'>
              <div className='col-sm-5'>
                <h2>Wrap - A campanion plugin</h2>
                <p>
                  Wrap is a clean and elegant Multipurpose Landing Page
                  Template. It will fit perfectly for Startup, Web App or any
                  type of Web Services. It has 4 background styles with 6
                  homepage styles. 6 pre-defined color scheme. All variations
                  are organized separately so you can use / customize the
                  template very easily.
                </p>

                <p>
                  It is a clean and elegant Multipurpose Landing Page Template.
                  It will fit perfectly for Startup, Web App or any type of Web
                  Services. It has 4 background styles with 6 homepage styles. 6
                  pre-defined color scheme. All variations are organized
                  separately so you can use / customize the template very
                  easily.
                </p>
                <div className='mh-about-tag'>
                  <ul>
                    <li>
                      <span>php</span>
                    </li>
                    <li>
                      <span>html</span>
                    </li>
                    <li>
                      <span>css</span>
                    </li>
                    <li>
                      <span>php</span>
                    </li>
                    <li>
                      <span>wordpress</span>
                    </li>
                    <li>
                      <span>React</span>
                    </li>
                    <li>
                      <span>Javascript</span>
                    </li>
                  </ul>
                </div>
                <a href='/' className='btn btn-fill'>
                  Live Demo
                </a>
              </div>
              <div className='col-sm-7'>
                <div className='mh-portfolio-modal-img'>
                  <img
                    src='assets/images/pr-0.jif'
                    alt=''
                    className='img-fluid'
                  />
                  <p>
                    All variations are organized separately so you can use /
                    customize the template very easily.
                  </p>
                  <img
                    src='assets/images/pr-1.jif'
                    alt=''
                    className='img-fluid'
                  />
                  <p>
                    All variations are organized separately so you can use /
                    customize the template very easily.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
