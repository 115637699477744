// import firebase from 'firebase/app';
import * as firebase from "firebase/app";

import 'firebase/database';
import 'firebase/functions';

import fb from '../config';

const config = {
  apiKey: fb.API_KEY,
  authDomain: fb.AUTH_DOMAIN,
  databaseURL: fb.DATABASE_URL,
  projectId: fb.PROJECT_ID,
  storageBucket: fb.STORAGE_BUCKET,
  messagingSenderId: fb.MESSAGING_SENDER_ID,
  appId: fb.APP_ID, 
  measurementId: fb.APP_ID, 
};

firebase.initializeApp(config);

export default firebase;
