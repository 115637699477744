export default {
  technicalSkills: [
    {
      id: 1,
      stack: 'Python',
      level: '97%'
    },
    {
      id: 2,
      stack: 'Javascript',
      level: '95%'
    },
    {
      id: 3,
      stack: 'PHP',
      level: '95%'
    },
    {
      id: 4,
      stack: 'C#',
      level: '95%'
    },
    {
      id: 5,
      stack: 'HTML&CSS',
      level: '95%'
    },
    {
      id: 6,
      stack: 'Headless - CMS',
      level: '95%'
    }
  ],
  softSkills: [
    {
      attribute: 'Communication',
      level: 99
    },
    {
      attribute: 'Team Intergration',
      level: 99
    },
    {
      attribute: 'Professionalism',
      level: 99
    },
    {
      attribute: 'Project Management',
      level: 99
    }
  ],
  frameworks: ['Python', 'Django', 'Flask', 'HTML', 'CSS', 'VanillaJS', 'ReactJS', 'React', 'Redux', 'NodeJS'],
  stacks: [
    {
      id: 1,
      title: 'API Development',
      description: 'Proficient in building robust and scalable REST & GraphQL APIs and web backends with NoSQL/SQL DB connected directly or via ORM or ODM',
      frameworks: ['Python-Django', 'Python-Flask', 'Fast-API', 'PHP-Laravel', 'RubyOnRails', 'Serverless-Functions', 'NodeJS'],

    },
    {
      id: 2,
      title: 'Web Development',
      description: 'Proficient in building professional and performant web frontend with top frameworks. Also worked on varied Content Management Systems(CMS)',
      frameworks: ['Nuxt-JS', 'Next', 'Remix', 'Vue', 'Svelte', 'AngularJS', 'React', 'React-Redux', 'WordPress', 'Drupal', 'Joomla', 'Strapi.js', 'Contentful'],

    },
    {
      id: 3,
      title: 'UI Design',
      description: 'Using the modern design tools and to deliver the high-fidelity prototypes from wireframes with great UX in mind.',
      frameworks: ['HTML', 'CSS', 'VanillaJS', 'ReactJS', 'VueJS', 'AngularJS', 'Invision/AdobeXD/Sketch'],

    }
  ],
  userInfo: {
    pic:
      'https://avatars0.githubusercontent.com/u/35098691?s=460&v=4',
    address: 'Nairobi, Kenya',
    email: 'koitoror@gmail.com',
    phone: '+254 721 102111',
    title: 'Software Engineer',
    name: 'Dan Kamar',
    bio:
      'Daniel Kamar is an experienced, self-taught, full-stack, language-agnostic, framework-independent, goal oriented tech-lead with the ability to manage a projects lifecycle efficiently with analytical and problem-solving skills.'
  },
  stories: {
    experiences: [
      {
        id: 4,
        title: 'Software Engineer / Tech-preneur',
        timeline: 'Mar 2014 – Present',
        company: 'Degem Ventures',
        description: `Responsibilities <br />
      <ul>
      <li>Documentation of systems and database design architecture.</li>
      <li>Assigning, Designing, Testing, Implementing and Approval of features and functionality.</li>
      <li>Packaging application versions and maintaining the code base.</li>
      <li>Addressing Issues of Policy Compliance, Security, Availability, Design and Performance reporting.</li>
      <li>Perform DevOps tooling and orchestration of cloud-first services in AWS, Azure, and GCP.</li>
      </ul>`
      },
      {
        id: 3,
        title: 'Software Engineer',
        timeline: 'Mar 2019 – Nov 2019 • 7 mos',
        company: 'Andela',
        description:
          `Andela provides companies with access to the top 1% of global tech talent to accelerate their product road-maps while minimizing time spent interviewing, onboarding, and training new hires.
          <br />
          Responsibilities;
          <br />
          <ul>
            <li>Define and deliver software.</li>
            <li>Develop readable, testable, and maintainable code to spec.</li>
            <li>Commit to continued technical and professional growth.</li>
            <li>Interface with partners.</li>
            <li>Lend my knowledge to the Andela community of engineers.</li>
          </ul>`
      },
      {
        id: 2,
        title: 'Software Technologist',
        timeline: 'Nov 2015 – Feb 2019 • 3 yrs 8 mos',
        company: 'Sphere Logic Communication',
        description: `Worked directly with our outsource clients. My main role was to quantify and analyze all jobs data to drive improvements to provide the best jobs experience.
      <br />
      Responsibilities;<br />
      <ul>
      <li>Streamlining of operational procedures and metrics between Data Aggregation, Customer Success and Sales teams.</li>
      <li>Data validation & visualization jobs data sets.</li>
      <li>Liaison with engineering teams on improving the collection and utilization of jobs data.</li>
      <li>Build new crawling tools in Vanilla JS.</li>
      <li>Report and fix various bugs in our web crawlers.</li>
      <li>Provide technical support for sales and customer success.</li>
      <li>Evaluate and improve internal error detection.</li>
      <li>Monitor and analyze the overall health of jobs data.</li>
      </ul>`
      },

      {
        id: 1,
        title: 'IT Support Specialist',
        timeline: 'Jan 2015 – Sep 2015 • 9 mos',
        company: 'Idyll Information Centre',
        description: `Responsibilities <br />
      <ul>
      <li>In-charge of setup, configuration and administration of all network hardware and software Layer-4 and Layer-7</li>
      <li>Establishing and maintaining system security of servers and clients systems and ensuring data integrity.</li>
      <li>Creating IT policy for the organization with support solutions and end-user requirements</li>
      <li>Prompt support resolution of end-user issues related to hardware and software malfunction.</li>
      <li>Providing training and IT support to users for applications.</li>
      </ul>`
      }
    ],
    studies: [
      {
        id: 1,
        title: "Bachelor of Information Science",
        company: 'Moi University',
        // timeline: '2014 - Present',
        description: ''
      }
    ]
  }
};
